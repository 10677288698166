import { ReportIntegration } from '@app/shared/models/integration-report';

import { Observable } from 'rxjs/Observable';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Pagination } from '@app/shared/models/pagination';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../../base.service';

@Injectable({
    providedIn: 'root'
})
export class ReportIntegrationService extends BaseService {

    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }
    getAllLog(queryParams?: {
        [key: string]: any;
    }): Observable<any> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });
        return this.http
            .get<any>(`${this.api}/log`, {
                params
            })
            .pipe(catchError(this.handleError<any>('getAllLog')));
    }

    reCreateOrdersPacco(nNF: any): Observable<any> {
        return this.http
            .post<any>(`${this.api}/integrations/pacco/xml`, nNF)
            .pipe(catchError(this.handleError<any>('reCreateOrdersPacco')));
    }

    create(reportIntegration: ReportIntegration): Observable<Pagination<any>> {
        return this.http
            .post<Pagination<any>>(`${this.api}/invoicetag/order`, reportIntegration)
            .pipe(catchError(this.handleError<Pagination<any>>('create')));
    }
}
