import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Role } from '../../models';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RoleService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }
    //  all(): Observable<Role[]> {
    //     return this.http
    //         .get<Role[]>(`${this.api}/roles`)
    //         .pipe(catchError(this.handleError<Role[]>('all', [])));
    // }

    all(queryParams?: {
        [key: string]: any;
    }): Observable<Role[]> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });
        return this.http
            .get<Role[]>(`${this.api}/roles/`, {
                params
            })
            .pipe(catchError(this.handleError<Role[]>('all', [])));
    }

    getUser(id: any, queryParams?:
        {
            [key: string]: any;
        }
    ): Observable<any> {
        const defaultParams = {};

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http.get(`${this.api}/roles/${id}`, { params })
            .pipe(catchError(this.handleError<Role>('get')));
    }

    create(roles: Role): Observable<Role> {
        return this.http
            .post<Role>(`${this.api}/roles/`, roles)
            .pipe(catchError(this.handleError<Role>('create')));
    }

    update(roles: Role): Observable<Role> {
        return this.http
            .put<Role>(
                `${this.api}/roles/update`, roles
            )
            .pipe(catchError(this.handleError<Role>('update')));
    }

    delete(id): Observable<Role> {
        return this.http
            .delete<Role>(`${this.api}/roles/${id}`)
            .pipe(catchError(this.handleError<Role>('delete')));
    }
}
