import { Router } from '@angular/router';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Customer } from '@app/shared';
import { ReportReceiptService } from '@app/shared/services/reports/report-receipt/report-receipt.service';

@Component({
  selector: 'app-report-receipt',
  templateUrl: './report-receipt.component.html',
  styleUrls: ['./report-receipt.component.scss']
})
export class ReportReceiptComponent implements OnInit {
  @Output() exportExcel = new EventEmitter<any>();

  @Input() customers: Customer;
  data: any = {};
  displayMonths = 1;

  constructor(private reportReceiptService: ReportReceiptService, private router: Router) { }

  ngOnInit() {
  }

  backRouter() {
    this.router.navigate(['/reports/overview']);
  }

  downloadXlsx() {
    const params = {
      dateFrom: this.toFormatDate(this.data.dateFrom),
      dateTo: this.toFormatDate(this.data.dateTo)
    };
    this.onExportExcel(params);
  }

  onExportExcel(params) {
    this.reportReceiptService.downloadReportExcel(params);
  }

  toFormatDate(date): String {
    const dateTime = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ');
    const [year, month, day, hour, minutes, seconds] = [
      ...dateTime.split(/[- :]/)
    ];
    return `${year}-${month}-${day}`;
  }

}
