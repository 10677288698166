import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-overview',
  templateUrl: './report-overview.component.html',
  styleUrls: ['./report-overview.component.scss']
})
export class ReportOverviewComponent implements OnInit {

  selectReport: any = 'slecione';
  report$ = [
    { value: 'order', name: 'Relatório de Pedidos' },
    { value: 'stock', name: 'Relatório de Estoque' },
    { value: 'picking', name: 'Relatório de Separação' },
    { value: 'nfsale', name: 'Relatório de Nota de Venda' },
    { value: 'position', name: 'Relatório de Posições do Cliente' }
  ];

  constructor(private router: Router) { }

  ngOnInit() { }

  reportRouter(event) {
    this.selectReport = event.target.value;
    const handle = setInterval(() => {
      if (this.selectReport) {
        if (this.selectReport === 'order') {
          this.router.navigate(['/reports/orders']);
        }
        if (this.selectReport === 'stock') {
          this.router.navigate(['/reports/stock']);
        }
        if (this.selectReport === 'picking') {
          this.router.navigate(['/reports/picking']);
        }
         if (this.selectReport === 'nfsale') {
          this.router.navigate(['/reports/nfsale']);
        }
         if (this.selectReport === 'position') {
          this.router.navigate(['/reports/position']);
        }
        clearInterval(handle);
      }
    }, 50);
  }
}
