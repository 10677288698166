import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { ReportIntegration } from '@app/shared/models/integration-report';
import { Observable } from 'rxjs';
import { type } from 'os';
import { LockChanges } from '@ngrx/store-devtools/src/actions';
import { ReportIntegrationService } from '@app/shared/services/reports/report-integration/report-integration.service';
import { AlertService } from '@app/shared/services/alert/alert.service';
import { DatePipe } from '@angular/common';
import { CustomerService } from '@app/shared';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-report-integration',
    templateUrl: './report-integration.component.html',
    styleUrls: ['./report-integration.component.scss'],
    providers: [DatePipe]
})
export class ReportIntegrationComponent implements OnInit {

    integration: any;
    logs: any;

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;


    _modalContent: any;
    dataSource: any;
    data: any = {};
    logResponse: any;

    displayedColumns: string[] = [
        'customerCode',
        'idCliPed',
        'codwsaida',
        'createdAt',
        'error'
    ];

    types = [
        { number: 0, name: 'Criação de Pedidos' },
        { number: 1, name: 'Postagem de XML de venda Ecommerce' },
        { number: 2, name: 'Postagem de XML de venda Atacado' },
        { number: 3, name: 'Postagem de NF de Entrada' },
        { number: 4, name: 'Integração PHX' },
        { number: 5, name: 'Integração ERP' },
        { number: 6, name: 'Webhook Volumes' },
        { number: 7, name: 'Envio da nota de remessa de retorno de armazenagem' },
        { number: 8, name: 'Webhook Status Pedido' },
        { number: 9, name: 'Email de volumetria' },
        { number: 10, name: 'Rastreio da Jadlog' },
        { number: 11, name: 'Email de recebimento de Carga' },
        { number: 12, name: 'Integração Omie' }
    ];

    details: any;
    form: FormGroup;

    typeNumber: number

    // nfWithoutOrder: any = [];
    // nfWithOrder: any[] = [];
    // nNF: string;
    customers: any[];

    constructor(
        private modalService: NgbModal,
        private reportIntegrationService: ReportIntegrationService,
        private alertService: AlertService,
        private datePipe: DatePipe,
        private customerService: CustomerService
    ) {
        this.customerService.all().subscribe((response) => {
            this.customers = response;
        });
        this.createForm();
    }

    clearForm() {
        this.createForm();
    }

    createForm() {
        this.form = new FormGroup({
            customerCode: new FormControl('', []),
            id_cli_ped: new FormControl('', [])
        });
    }

    ngOnInit() { }

    formValues(): Object {
        const { valid, value } = this.form;
        return {
            customerCode: value.customerCode,
            id_cli_ped: value.id_cli_ped
        };
    }

    async start() {
        const handle = setInterval(() => {
            if (this.logs) {
                // this.inventorys.map((data) => {
                //     if (data.status === 0) {
                //         data.status = 'Aberto';
                //     } else {
                //         data.status = 'Fechado';
                //     }
                // })
                this.dataSource = new MatTableDataSource<any>(this.logs);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                clearInterval(handle);
            }
        }, 200);
    }


    getCustomerName(customerCode) {
        const res = this.customers.find((customer) => {
            return customer.code === customerCode;
        });
        return res.name;
    }
    selectType(event) {
        this.typeNumber = event.target.value
        const data = {
            limit: 100,
            type: event.target.value
        }
        this.reportIntegrationService.getAllLog(data).subscribe((response) => {
            if (response.logs.length == 0) {
                this.alertService.error('Nenhum LOG desse Tipo Encontrado')
            } else {
                this.logs = response.logs
                this.start();
            }
        });
    }

    open(content, log) {
        switch (log.type) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 7:
            case 8:
                this.logResponse = log.error;
                break;
            case 6:
                this.logResponse = log.customerResponse.msg;
                break;
            case 9:
                const rejectedEmails = log.content.rejected.join('<br>');
                this.logResponse = `Emails rejeitados:<br>${rejectedEmails}`;
                break;
            case 10:
                this.logResponse = log.content.erro.descricao;
                break;
            case 11:
                const rejectedReceiptEmails = log.lmxResponse.rejected.join('<br>');
                this.logResponse = `Emails rejeitados:<br>${rejectedReceiptEmails}`;
                break;
                break;
            case 12:
                this.logResponse = log.wmsResponse.erros;
                break;
            default:
                this.logResponse = 'Tipo de log não reconhecido';
        }

        this.modalService.open(content, {
            size: 'lg'
        });
    }

    onSearch() {
        const q = this.formValues();
        const filteredQ = {};

        for (const key in q) {
            if (q[key] !== '') {
                filteredQ[key] = q[key];
            }
        }
        console.log({ ...filteredQ })

        const data = {
            limit: 100,
            type: this.typeNumber,
            ...filteredQ
        }
        this.reportIntegrationService.getAllLog(data).subscribe((response) => {
            if (response.logs.length == 0) {
                this.alertService.error('Nenhum LOG desse Tipo Encontrado')
            } else {
                this.logs = response.logs
                this.start();
            }
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    dismiss() {
        this.modalService.dismissAll();
    }

    formatDate(date: string): string {
        return this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss') || '';
    }

    toogleSearch() {
        document.querySelector('.barSearch').classList.toggle('opened');
        document
            .querySelector('.contentDetailsFilter')
            .classList.toggle('enabled');
    }
}

