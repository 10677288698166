import { Router } from '@angular/router';
import { Customer } from './../../../../shared/models/customer';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { CustomerService } from '@app/shared';
import { ReportsService } from '@app/shared/services/reports/reports.service';

@Component({
    selector: 'app-report-customer-position',
    templateUrl: './report-customer-position.component.html',
    styleUrls: ['./report-customer-position.component.scss']
})
export class ReportCustomerPositionComponent implements OnInit {
    customers: any[];

    data: any = {};
    displayMonths = 1;

    constructor(private router: Router, private _reportService: ReportsService, private _customerService: CustomerService) {
        this._customerService.all().subscribe((customers) => {
            this.customers = customers;
        });
    }

    ngOnInit() { }

    downloadXlsx() {
        const params = {
            customerCode: this.data.customer,
            dateFrom: this.toFormatDate(this.data.dateFrom),
            dateTo: this.toFormatDate(this.data.dateTo)
        };
        console.log(params)
        this._reportService.downloadExcellCustomerPosition(params);
    }

    toFormatDate(date): String {
        const dateTime = new Date(date)
            .toISOString()
            .slice(0, 19)
            .replace('T', ' ');
        const [year, month, day, hour, minutes, seconds] = [
            ...dateTime.split(/[- :]/)
        ];
        return `${year}-${month}-${day}`;
    }

    backRouter() {
        this.router.navigate(['/reports/overview']);
    }
}
