import { Injectable } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AlertService } from '../../services/alert/alert.service';
import { Observable } from 'rxjs';
import { Pagination } from '@app/shared/models/pagination';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OrderCustomerService extends BaseService {
    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    getAllOrdersCustomer(
        customer_code: number,
        typeOrders?: string,
        queryParams?: { [key: string]: any }
    ): Observable<any> {
        const defaultParams = {
            customer_code: customer_code,
            type: typeOrders
        };
        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });
        return this.http
            .get<any>(`${this.api}/customer/orders/getTrelloOrdersByCustomer`, { params })
            .pipe(catchError(this.handleError<any>('getIndex')));
    }

    getIndex(
        customer_id: number,
        queryParams?: { [key: string]: any }
    ): Observable<Pagination<any>> {
        const defaultParams = {
            customer_id: customer_id
        };

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http
            .get<Pagination<any>>(`${this.api}/customer/orders`, { params })
            .pipe(catchError(this.handleError<Pagination<any>>('getIndex')));
    }

    getAll(
        customer_id: number,
        queryParams?: { [key: string]: any }
    ): Observable<any> {
        const defaultParams = {
            customer_id: customer_id
        };
        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });
        // headers.has('ignore-loading-indicator')
        const headers = new Headers();
        headers.append('ignore-loading-indicator', 'true');
        return this.http
            .get<any>(`${this.api}/customer/orders/all`, { params })
            .pipe(catchError(this.handleError<any>('getIndex')));
    }

    export(
        customer_id: number,
        queryParams?: { [key: string]: any }
    ): Observable<Blob> {
        const defaultParams = {
            customer_id: customer_id
        };

        queryParams = {
            ...defaultParams,
            ...queryParams
        };

        const params = new HttpParams({ fromObject: queryParams });

        return this.http
            .get<Blob>(`${this.api}/customer/orders/export`, {
                params,
                observe: 'response',
                responseType: 'blob' as 'json'
            })
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    this.saveFile(response);
                    return response.body;
                }),
                catchError(this.handleError<Blob>('export'))
            );
    }


    reportTrelloCustomerXLSX(data: any) {
        this.downloadXLS(
            `${this.api}/reports/orders/order-tracking-report`,
            
                data
            ,
            'Relatório trello'
        );
    }
}
