import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import { FtpIntegrationsComponent } from './containers/ftp-integrations/ftp-integrations.component';
import { MaterialModulesModule } from '@app/shared/modules/material-modules/material-modules.module';
import { DataTableListComponent } from './components/data-table-list/data-table-list.component';
import { NgbDateAdapter,
         NgbDateNativeAdapter,
         NgbDateParserFormatter,
         NgbDatepickerI18n,
         NgbDatepickerModule,
         NgbModalModule,
         NgbModule,
         NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { OrdersReportsComponent } from './containers/orders-reports/orders-reports.component';
import { ReportOrdersComponent } from './components/report-orders/report-orders.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomDatepickerI18n, I18n, NgbDateCustomParserFormatter, PageHeaderModule, ShowErrorsModule } from '@app/shared';
import { SharedDirectivesModule } from '@app/shared/directives/shared-directives.module';
import { ConferenceReportComponent } from './containers/conference-report/conference-report.component';
import { ReportConferencesComponent } from './components/report-conferences/report-conferences.component';
import { TableReportComponent } from './containers/table-report/table-report.component';
import { ReportTableComponent } from './components/report-table/report-table.component';
import { StockReportComponent } from './containers/stock-report/stock-report.component';
import { ReportStockComponent } from './components/report-stock/report-stock.component';
import { IntegrationReportComponent } from './containers/integration-report/integration-report.component';
import { ReportIntegrationComponent } from './components/report-integration/report-integration.component';
import { ReportPickingComponent } from './components/report-picking/report-picking.component';
import { PickingReportComponent } from './containers/picking-report/picking-report.component';
import { ReportOverviewComponent } from './components/report-overview/report-overview.component';
import { ReportCustomerPositionComponent } from './components/report-customer-position/report-customer-position.component';
import { ReportNfsaleComponent } from './components/report-nfsale/report-nfsale.component';
import { ReportReceiptComponent } from './components/report-receipt/report-receipt.component';

@NgModule({
    imports: [
        NgbModule ,
        NgbModalModule,
        CommonModule,
        NgbModule,
        NgbModalModule,
        NgbDatepickerModule,
        NgbPaginationModule,
        SharedDirectivesModule,
        FormsModule,
        ReactiveFormsModule,
        ShowErrorsModule,
        ReportsRoutingModule,
        MaterialModulesModule,
        PageHeaderModule,
        TranslateModule
    ],
  declarations: [
      FtpIntegrationsComponent,
      DataTableListComponent,
      OrdersReportsComponent,
      ReportOrdersComponent,
      ConferenceReportComponent,
      ReportConferencesComponent,
      TableReportComponent,
      ReportTableComponent,
      StockReportComponent,
      ReportStockComponent,
      IntegrationReportComponent,
      ReportIntegrationComponent,
      ReportPickingComponent,
      PickingReportComponent,
      ReportOverviewComponent,
      ReportCustomerPositionComponent,
      ReportNfsaleComponent,
      ReportReceiptComponent
    ],
    providers: [
        [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter
        },
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }
    ]
})
export class ReportsModule { }
