import { Injectable } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AlertService } from '../../services/alert/alert.service';
import { Observable } from 'rxjs';
import { Pagination } from '@app/shared/models/pagination';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ReportsService extends BaseService {

    constructor(public http: HttpClient, alertService: AlertService) {
        super(alertService, http);
    }

    getAll(queryParams?: { [key: string]: any }): Observable<any> {
        const defaultParams = {};
        queryParams = {
            ...defaultParams,
            ...queryParams
        };
        const params = new HttpParams({ fromObject: queryParams });
        return (
            this.http
                // .get(`${this.api}/orders/`)
                .get<any>(`${this.api}/reports/ftp/`, { params })
                .pipe(catchError(this.handleError<any>('getIndex')))
        );
    }

    downloadExcellNfSaleCustomer( params: any) {
        this.downloadXLS(`${this.api}/reports/orders/nfsale/client`, params, 'nfSale');
    }

    downloadExcellCustomerPosition( params: any) {
        this.downloadXLS(`${this.api}/reports/orders/client-position`, params, 'positions');
    }
}
